import React, { useState, useEffect } from "react"
import SEO from "../components/seo"

export default () => {
  const [loaded, error] = useScript(
    "https://jordank.activehosted.com/f/embed.php?id=18"
  )
  return (
    <>
      <SEO title="Free Affiliate Guide" />
      <meta name="viewport" content="width=device-width, user-scalable=no" />
      <link
        href="https://fonts.googleapis.com/css?family=Oswald|Open Sans|&display=swap"
        rel="stylesheet"
      />
      <div>
        <div
          dangerouslySetInnerHTML={{
            __html: `
        <style>
          body {
              margin: 0;
              font-family: "Open Sans"
            }
            .gjs-row {
              width: 100%;
            }
            .gjs-cell {
              width: 8%;
              display: table-cell;
              height: 75px;
            }
            button[type="submit"] {
              letter-spacing: 1px;
              text-transform: none !important;
              font-weight: 550 !important;
              font-family: inherit !important;
              font-size: 19px !important;
            }
            main {
              background-image: url("https://assets.clickfunnels.com/images/grey-geo.png");
            }
            body {
              background-image: url("https://assets.clickfunnels.com/images/grey-geo.png");
              background-repeat: repeat;
              background-position: left top;
              background-attachment: scroll;
              background-size: auto;
              background-color: #ffffff;
            }
            #i7gn {
              width: 38%;
              background-color: #ffffff;
              border-radius: 24px;
              box-shadow: 0 4px 5px 0 #7e7e7e;
              margin: 0 0 0 0;
              text-align: center;
              padding: 15px 15px 0 15px;
            }
            #iha8 {
              margin: 20px 0 0 0;
             
            }
            #ix2l {
              border: 3px dashed black;
              padding: 0 0 0 0;
              transition: transform 2s ease-in;
              perspective: 13px;
              transform: rotateX(12deg) rotateY(0) rotateZ(0) scaleX(1) scaleY(1) scaleZ(1);
              border-radius: 6px;
              box-shadow: 0 0 5px 0 black;
            }
            #wrapper {
              padding: 10px;
            }
            #ie62a {
              height: 100%;
              display: inline-block !important;
              position: relative;
              text-align: center;
              width: 498.2px;
            }
            #iyz8c {
              text-align: center;
              height: 100%;
              padding: 0 0 0 0;
            }
            #ihyxi {
              padding: 1rem;
              border-radius: 1px;
            }
            #ijeav {
              padding: 10px;
              position: static;
              top: auto;
              margin: 0 0 0 0;
              transition: opacity 2s ease;
            }
            #ib5r6 {
              padding: 10px;
            }
            #ib3ss {
              margin-top: 10px;
              -webkit-tap-highlight-color: transparent;
              -webkit-font-smoothing: antialiased;
              line-height: inherit;
              text-align: center;
              -webkit-appearance: none;
              font-size: 16px;
              padding: 9px;
              width: 100%;
              background-color: #fff;
              border: 2px solid #e6e6e6;
              border-radius: 5px;
            }
            #iy104 {
              margin-top: 10px;
              -webkit-tap-highlight-color: transparent;
              -webkit-font-smoothing: antialiased;
              line-height: inherit;
              text-align: center;
              -webkit-appearance: none;
              font-size: 16px;
              padding: 9px;
              width: 100%;
              background-color: #fff;
              border: 2px solid #e6e6e6;
              border-radius: 5px;
            }
            #ik954 {
              margin-top: 10px;
              -webkit-tap-highlight-color: transparent;
              -webkit-font-smoothing: antialiased;
              line-height: inherit;
              text-align: center;
              -webkit-appearance: none;
              font-size: 16px;
              padding: 9px;
              width: 100%;
              background-color: #fff;
              border: 2px solid #e6e6e6;
              border-radius: 5px;
            }
            #i4hkw {
              margin-top: 10px;
              -webkit-tap-highlight-color: transparent;
              -webkit-font-smoothing: antialiased;
              line-height: inherit;
              text-align: center;
              -webkit-appearance: none;
              font-size: 16px;
              padding: 9px;
              width: 100%;
              background-color: #fff;
              border: 2px solid #e6e6e6;
              border-radius: 5px;
            }
            #i5jsl {
              width: 100%;
              margin-top: 10px;
              line-height: 1.42857143;
              -webkit-font-smoothing: antialiased;
              box-sizing: border-box;
              background: 0 0;
              transition: 0.2s;
              display: inline-block;
              text-align: center;
              padding-top: 10px;
              padding-bottom: 10px;
              padding-left: 25px;
              padding-right: 25px;
              border-radius: 3px;
              font-size: 18px;
              border: 1px solid rgba(0, 0, 0, 0.2);
              text-decoration: none;
              box-shadow: 0 0 5px 0 black;
              color: #ffffff;
              background-color: #e31a13;
            }
            footer {
              padding-top: 1.5rem;
              padding-bottom: 1.5rem;
              text-align: center;
              width:100%;
            } 
            @media (max-width: 992px) {
              #i7gn {
                width: 100%;
                height: 100%;
              }
              #ix2l {
                height: 100%;
              }
            }

            @media (max-width: 480px) {
              .gjs-cell {
                width: 100%;
                display: block;
              }
              #i7gn {
                width: 100%;
                padding: 15px 15px 0 15px;
              }
              #iha8 {
                height: 100%;
                margin: 0 0 0 0;
                padding: 4px 0 4px 0;
                width: 100%;
              }
              #ix2l {
                width: 100%;
              }
              #iyz8c {
                padding: 7px 0 0 0;
                width: auto;
              }
              #ie62a {
                height: 100%;
                width: 100vw;
              }
              #ijeav {
                margin: 0 0 0;
                display: block;
                border: 0 solid black;
                background-attachment: scroll;
                background-size: auto;
                background-repeat: no-repeat;
                background-position: center center;
                transition: opacity 2s ease;
              }
            }
            p {
              margin: 0;
            }
             
            
          </style>`,
          }}
        />
        <div id="iyz8c" className="gjs-row" style={{ fontFamily: "Open Sans" }}>
          <div id="ie62a" className="gjs-cell">
            <div id="iha8" className="gjs-row">
              <div id="i7gn" className="gjs-cell">
                <div id="ijbj" className="gjs-row">
                  <div id="ix2l" className="gjs-cell">
                    <div id="ihyxi">
                      <p>
                        <span style={{ color: "#e74c3c" }}>
                          <span style={{ fontSize: "28px" }}>
                            <span style={{ fontFamily: "Oswald" }}>
                              <strong>
                                Exclusive FREE Guide Teaches You How to Start a
                                10k+ Affiliate Business
                              </strong>
                            </span>
                          </span>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div id="ijeav">
                  <p>
                    <span style={{ fontSize: "18px" }}>
                      <span style={{ color: "#000000" }}>
                        After several years of research, I've compiled this
                        guide on how to start bringing in
                      </span>
                      <strong>
                        <span style={{ color: "#0066ff" }}> $10,000+</span>
                      </strong>
                      <span style={{ color: "#000000" }}>
                        {" "}
                        with Affiliate Marketing.
                      </span>
                    </span>
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>
                    <span style={{ fontSize: "18px" }}>
                      <span style={{ color: "#000000" }}>
                        In this guide you'll learn a new methodology to
                        affiliate marketing that leverages the most powerful
                        platforms in the world. All of this{" "}
                      </span>
                      <span style={{ color: "#0066ff" }}>
                        <em>step by step</em>
                      </span>
                      <span style={{ color: "#000000" }}>.</span>
                    </span>
                  </p>
                </div>

                <div className="_form_18" style={{ marginTop: "-25px" }}></div>
                <div id="ib5r6">
                  <p>
                    <span style={{ color: "#7f8c8d" }}>
                      * We Will Not Spam, Rent, Or Sell Your Information *
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer id="footer" align="center" className="main-footer">
        <p>© Millionaire Millennial {new Date().getFullYear()}</p>
      </footer>
    </>
  )
}

// Hook
let cachedScripts = []
function useScript(src) {
  // Keeping track of script loaded and error state
  const [state, setState] = useState({
    loaded: false,
    error: false,
  })

  useEffect(
    () => {
      // If cachedScripts array already includes src that means another instance ...
      // ... of this hook already loaded this script, so no need to load again.
      if (cachedScripts.includes(src)) {
        setState({
          loaded: true,
          error: false,
        })
      } else {
        cachedScripts.push(src)

        // Create script
        let script = document.createElement("script")
        script.src = src
        script.async = true

        // Script event listener callbacks for load and error
        const onScriptLoad = () => {
          setState({
            loaded: true,
            error: false,
          })
        }

        const onScriptError = () => {
          // Remove from cachedScripts we can try loading again
          const index = cachedScripts.indexOf(src)
          if (index >= 0) cachedScripts.splice(index, 1)
          script.remove()

          setState({
            loaded: true,
            error: true,
          })
        }

        script.addEventListener("load", onScriptLoad)
        script.addEventListener("error", onScriptError)

        // Add script to document body
        document.body.appendChild(script)

        // Remove event listeners on cleanup
        return () => {
          script.removeEventListener("load", onScriptLoad)
          script.removeEventListener("error", onScriptError)
        }
      }
    },
    [src] // Only re-run effect if script src changes
  )

  return [state.loaded, state.error]
}
